import React from "react"
import Layout from '../components/layout/layout';
import { injectIntl } from "gatsby-plugin-intl";
import { 
  ContactSection,
  Footer,
  Header,
  MeetingAppHero,
  SeeMoreSection,
  SEO,
  ServiceDescriptionSection,
  SFMImageSection
} from "../components";

const MeetingAppPage = ({intl}) => {

  return(
    <Layout>
      <SEO title="Meeting App" lang={intl.locale} description={intl.formatMessage({ id: `meeting-app.site.description`})} />
      <Header isNotTransparent />
      <MeetingAppHero isSecondaryHero/>
      <ServiceDescriptionSection
        page={'meeting-app'}
      />
      <SFMImageSection/>
      <ContactSection page={'meeting-app'}/>
      <SeeMoreSection page={'meeting-app'}/>
      <Footer/>
    </Layout>
  )
}

export default injectIntl(MeetingAppPage)
